import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../components/seo"
import ContentPageLayout from "../../components/ContentPageLayout"
import { getHome } from "../../api/content"
import TextField from '@material-ui/core/TextField';
import SCButton from "../../components/buttons/SCButton";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import LockIcon from '@material-ui/icons/Lock';
import CheckIcon from '@material-ui/icons/Check';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

// import { getDatabase, ref, onValue } from "firebase/database";
import firebase from "firebase";

const PasswordResetPage = ({ location }) => {

  const [isExpiredCode, setIsExpiredCode] = React.useState(undefined);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [validatePassword, setValidatePassword] = React.useState("");
  const [passwordDone, setPasswordDone] = React.useState(false);

  const params = new URLSearchParams(location.search)
  const oobCode = params.get('oobCode');


  React.useEffect(() => {

    firebase.auth().verifyPasswordResetCode(oobCode).then(() => {
      return setIsExpiredCode(false)
    })
      .catch(error => {
        return setIsExpiredCode(true)
      });
  }, [])

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const previousPassword = newPassord => {
    if (newPassord === 'test password1A') {
      return false;
    } else {
      return true;
    }
  }

  const charEspecial = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const alphanumericRegex = /[0-9]/;
  const upperCaseRegex = /[A-Z]/;
  const acentos = /[áéíóúÁÉÍÓÚ]/;

  const confirm = () => {
    if (password != confirmPassword) {
      setValidatePassword('Contraseñas no coinciden');
      return false
    } else if ((password == confirmPassword) && (password && confirmPassword !== "")) {
      if (!upperCaseRegex.test(password)) {
        setValidatePassword('La contraseña debe de tener al menos una letra en mayúscula')
        return false
      }
      if (!alphanumericRegex.test(password)) {
        setValidatePassword('La contraseña debe contener letras y números.')
        return false
      }
      if (!charEspecial.test(password)) {
        setValidatePassword("La contraseña debe de contener al menos un caracter especial: @ $ ? ¡ \ - _ % ^ & ( ) +")
        return false
      }
      if (acentos.test(password)) {
        setValidatePassword("Los acentos no están permitidos")
        return false
      }
      if (password.length < 8) {
        setValidatePassword('La contraseña debe mayor a 8 caracteres');
        return false
      }
      if (!upperCaseRegex.test(password)) {
        setValidatePassword("La contraseña debe de tener al menos una letra en mayúscula")
        return false
      }
      if (!alphanumericRegex.test(password)) {
        setValidatePassword("La contraseña debe contener letras y números.")
        return false
      }
      if (previousPassword(password)) {
        setValidatePassword('')
        firebase.auth().confirmPasswordReset(oobCode, password)
          .then(() => {
            setPasswordDone(true)
            return true
          })
          .catch(error => {
            console.log(error.code, error.message)
          })
      } else {
        setValidatePassword('Ingrese una contraseña diferente a la que se registró anteriormente');
        return false
      }
    } else {
      setValidatePassword('Ingrese una contraseña válida')
      return false
    }
  }

  // console.log(validatePassword)

  return (
    <ContentPageLayout>
      <Seo title="Recupera tu contraseña" />
      {
        isExpiredCode === undefined ? <></>
          :
          !isExpiredCode ? (
            <>
              <div>
                <div className="flex relative" style={{ height: 240, width: "100%" }}>
                  <StaticImage alt="Banner image" src="../../images/banner_superior.png" style={{ width: "100%", height: "100%", objectFit: "cover" }}></StaticImage>
                  <div className="absolute flex p-5 items-end h-full">
                    <h1 className="text-white"></h1>
                    <p className="text-white"></p>
                  </div>
                </div>
              </div>
              {
                !passwordDone ?
                  <div className="p-12 md:w-96 md:p-0 md:mt-7  pb-24 mx-auto my-0">
                    <div className="w-full h-full m-0 flex flex-col" >
                      <h1 className='text-center'>Cambiar contraseña</h1>
                      <p className='text-justify'>
                        La contraseña debe ser diferente a la anterior, esta debe contener una letra en mayúscula y  al menos  8 caracteres alfanuméricos.
                      </p>
                      <label className='text-left mb-4' >Ingresa tu nueva contraseña</label>
                      <TextField
                        style={{ width: '100%', height: '42px' }}
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => { setShowPassword(!showPassword) }}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      <label className='text-left my-4' >Confirma  tu nueva contraseña</label>
                      <TextField
                        style={{ width: '100%', height: '42px' }}
                        type={showConfirmPassword ? 'text' : 'password'}
                        value={confirmPassword}
                        onChange={(e) => {
                          setConfirmPassword(e.target.value.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => { setShowConfirmPassword(!showConfirmPassword) }}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                      <div className={`${confirm ? 'mt-2' : 'mt-8'} mx-auto`} style={{ width: '281px', height: '44px' }}>
                        <div className='text-center text-red-500 mb-4'>{`${validatePassword}`}</div>
                        <SCButton onClick={confirm} >Confirmar</SCButton>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="p-12 md:w-96 md:p-0 md:mt-7  pb-24 mx-auto my-0">
                    <div className="flex items-center h-12 mb-8">
                      <div className="flex justify-center items-center w-12 h-12 mr-2 rounded-full shadow border text-sc-primary">
                        <VpnKeyIcon></VpnKeyIcon>
                      </div>
                      <h3 className="text-sc-black mb-0">¡Bienvenido (a) de vuelta a Shift!</h3>
                    </div>
                    <hr className='' style={{ height: '4px', backgroundColor: '#C4C4C4' }} />
                    <div className="w-full mt-4 mb-24">
                      <p>Tu contraseña ha sido restablecida exitosamente. </p>
                    </div>
                    <div className="mt-8 mx-auto" style={{ width: '281px', height: '44px' }}>
                      <Link to="/"><SCButton>Aceptar</SCButton></Link>
                    </div>
                    <div>
                    </div>

                  </div>
              }
            </>
          ) : (
            <>
              <div>
                <div className="flex relative" style={{ height: 240, width: "100%" }}>
                  <StaticImage alt="banner Image" src="../../images/banner_superior.png" style={{ width: "100%", height: "100%", objectFit: "cover" }}></StaticImage>
                  <div className="absolute flex p-5 items-end h-full">
                    <h1 className="text-white"></h1>
                    <p className="text-white"></p>
                  </div>
                </div>
              </div>
              <div className="p-12 md:w-96 md:p-0 md:mt-7  pb-24 mx-auto my-0">
                <div className="w-full h-full m-0 flex flex-col" >
                  <h1 className='text-center'>El enlace ha expirado</h1>
                  <p className='text-justify'>
                    Solicita nuevamente el proceso de recuperar contraseña para generarte un nuevo enlace.
                  </p>
                  <div className='mt-8 mx-auto' style={{ width: '281px', height: '44px' }}>
                    <Link to="/"> <SCButton>Confirmar</SCButton> </Link>
                  </div>
                </div>
              </div>
            </>
          )
      }


    </ContentPageLayout>)
}

export default PasswordResetPage
